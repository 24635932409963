<template>
  <div class="app-container">
    <!-- 头部 -->
    <div class="header-content">
      <div class="w header">
        <div class="left">
          <img src="@/assets/images/icon/logo_icon.png" />
          <span class="title">易聊天</span>
        </div>
        <div class="middle">
          <div
            class="item"
            :class="{ active: isActive == index }"
            v-for="(item, index) in navList"
            :key="index"
            @click.stop="navClick('#id' + index, index)"
          >
            <span>{{ item.text }}</span>
          </div>
        </div>
        <div class="right">
          <div class="download">
            <a class="btn" :href="downloadUrl" download="download"
              >Windows下载</a
            >
          </div>
          <div class="register" @click.stop="openLink">登录/注册</div>
        </div>
      </div>
    </div>
    <!-- 背景图 -->
    <div id="id0" class="banner-container" style="padding-top: 66px !important">
      <img class="bgc" src="@/assets/images/home/banner.png" />
    </div>
    <!-- 一键了解 -->
    <div id="id1" class="w understand-content" style="padding-top: 30px">
      <div class="lunbo-content">
        <div class="top">
          <h3 class="title">这里有你想要的</h3>
          <span
            >易聊天是一款实用的聊天辅助工具，自动吸附多种聊天工具如：QQ/微信/旺旺等</span
          >
          <span
            >支持一键发送文字、图片、视频、微信多开，企业微信多开，话术导入/导出等大幅度提高工作效率</span
          >
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in functionList" :key="index">
          <img :src="item.imgPath" />
          <div class="text-container">
            <span class="t1">{{ item.text1 }}</span>
            <span class="t2">{{ item.text2 }}</span>
            <span class="t2">{{ item.text3 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 基础操作演示 -->
    <div id="id2" class="w function-content" style="padding-top: 70px">
      <h3 class="title-h3" style="padding-bottom: 70px">基础操作演示</h3>
      <div class="item">
        <!-- <div class="img-container">
          <img src="@/assets/images/home/jiemian.png" />
        </div> -->
        <div class="guide">
          <!-- <img src="" alt="操作指引" /> -->
        </div>
        <div class="right">
          <!-- <h4 class="title">兼容多种平台</h4> -->
          <div class="t-cont">
            <div class="hint-cont">
              <span class="hint"></span>
            </div>
            <span class="text t1">轻触一键发送，双击至消息回复栏位编辑。</span>
          </div>
          <div class="t-cont">
            <div class="hint-cont">
              <span class="hint"></span>
            </div>
            <span class="text t2">支持添加多张图片，图文穿插自定义编辑。</span>
          </div>
          <div class="t-cont">
            <div class="hint-cont">
              <span class="hint"></span>
            </div>
            <span class="text t2">
              在不同的设备上登录易聊天，能获得编辑过的所有话术，支持实时话术
              同步，让话术如影随行。
            </span>
          </div>
          <div class="btn-container">
            <div class="btn1" @click="goOne">易聊天图文教程</div>
            <div class="btn1" @click="goTwo">易聊天视频教程</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 吸附平台 -->
    <div id="id3" class="w understand-content" style="padding-top: 60px">
      <div class="lunbo-content">
        <div class="top">
          <h3 class="title">支持吸附多种聊天工具</h3>
          <span style="margin: 25px 0px">
            解决工作中要使用多种聊天工具，但快捷回复不互通的问题。实现兼容不同聊天工具共用同一套话术模板
          </span>
        </div>
      </div>
      <div class="list">
        <div
          class="platformItem"
          v-for="(item, index) in platformList"
          :key="index"
        >
          <img :src="item.imgPath" />
          <div class="text-container">
            <span class="t2">{{ item.text1 }}</span>
          </div>
        </div>
      </div>
      <div class="serviceText">需要吸附更多聊天软件请联系客服</div>
    </div>
    <!-- 应用场景 -->
    <div id="id4" class="w use-content" style="padding-top: 70px">
      <h3 class="title-h3">应用场景</h3>
      <div class="text">
        体验团队版，同一套优质话术可多人共享，整个客服团队沟通技巧统一高标准提升订单转化率，话术质量提升100%
      </div>
      <!-- <div class="text">
        支持一键发送文字、图片、视频、微信多开，企业微信多开，话术导入/导出等大幅度提高工作效率
      </div> -->
      <video id="myVideo" class="video-js">
        <source :src="videoUrl" type="video/mp4" />
      </video>
    </div>
    <!-- 适用人群 -->
    <div id="id5" class="w applyTo-content" style="padding-top: 70px">
      <h3 class="title-h3">适用人群</h3>
      <div class="list">
        <div class="item" v-for="(item, index) in applyToList" :key="index">
          <img :src="item.imgPath" />
          <div class="info">
            <span class="t1">{{ item.text1 }}</span>
            <span class="t2">{{ item.text2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 版本对比 -->
    <div id="id6" class="w member-content" style="padding-top: 70px">
      <h3 class="title-h3">版本对比</h3>
      <img
        class="bgc"
        :src="
          'https://yiliaotian.oss-cn-hangzhou.aliyuncs.com/product/product_table.png?timestamp=' +
          new Date().getTime()
        "
      />
      <!-- <img class="bgc" src="@/assets/images/home/priceList.png" /> -->
      <!-- <div class="text">易聊天持续迭代中，新的功能期待您的参与</div> -->
    </div>
    <!-- 了解价格 -->
    <div id="id7" class="w realize-content" style="padding-top: 70px">
      <h3 class="title-h3">会员价格</h3>
      <!-- <div class="textInfo">概览会员定价，聊天助手就选易聊天</div> -->
      <div class="list">
        <div class="item" v-for="(item, index) in memberList" :key="index">
          <img :src="item.imgPath" />
          <span
            :style="{
              color: item.color[0],
              background:
                'linear-gradient(to top,' +
                item.color[1] +
                ',' +
                item.color[2] +
                ')',
              cursor: 'pointer',
            }"
            @click.stop="goLogin"
          >
            立即订购
          </span>
        </div>
      </div>
    </div>
    <!-- 右侧提示 -->
    <div class="right-tips">
      <ul>
        <li class="t1">
          <img src="@/assets/images/icon/service.png" alt="" />
          <div id="welfareOne">
            <img :src="workWxQrCodePng" class="qrCode" />
            <div class="oRcode">
              <span
                style="
                  display: block;
                  font-size: 14px;
                  font-weight: 400;
                  color: #3d485d;
                  margin-top: 5px;
                  background-color: rgba(0, 107, 255, 0.1);
                  border-radius: 50px;
                  padding: 5px 8px;
                "
              >
                <img
                  src="@/assets/images/icon/serve.png"
                  style="width: 20px; height: auto"
                />
                在线客服扫码咨询
              </span>
            </div>
            <div id="triangle-right"></div>
          </div>
        </li>
        <li class="t2">
          <img src="@/assets/images/icon/phone.png" alt="" />
          <div id="welfareThree">
            <div class="oRcode">
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                  color: #3d485d;
                  margin-top: 4px;
                "
              >
                <span>客服电话：</span>
                <div>
                  <!-- <span style="display: block">0755-33010721</span>
                  <span style="display: block">400-015-7966</span> -->
                  <span style="display: block">400-636-2988</span>
                </div>
              </div>
            </div>
            <div id="triangle-right"></div>
          </div>
        </li>
        <li class="t3">
          <img src="@/assets/images/icon/more-icon.png" alt="" />
          <div id="welfareTwo">
            <div class="oRcode">
              <div class="textInfo">
                <span class="t1">商务合作</span>
                <span class="t2">-萧先生-</span>
              </div>
              <img
                style="margin-top: 7px"
                src="@/assets/images/icon/code.png"
                alt=""
              />
            </div>
            <div id="triangle-right"></div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 底部 -->
    <div class="footer_wrapper">
      <div class="footer_box">
        <div class="left_box">
          <div class="title">联系我们</div>
          <ul>
            <!-- <li>客服QQ：800 139 333</li> -->
            <li>固定电话：400-636-2988</li>
            <li>电子邮箱：fuwu@foonsu.com</li>
            <li>总部：苏州工业园区金鸡湖大道88号人工智能产业园G2-303室</li>
            <li>深圳：广东省深圳市宝安区新安街道44区金宝商务大厦3楼8326室</li>
            <li>广州：广东省广州市天河区中山大道西1132号四层405房</li>
            <li>
              杭州：浙江省杭州市拱墅区候圣街99号财智顺丰创新中心1幢1单元1109室
            </li>
          </ul>
        </div>
        <div class="right_box">
          <div class="title">丰速旗下产品</div>
          <ul>
            <li>
              <a target="_blank" href="https://www.foonsu.com/qdb">企得宝</a>
            </li>
            <li>
              <a target="_blank" href="https://www.foonsu.com/ydd">易打单</a>
            </li>
            <li>
              <a target="_blank" href="https://www.1fendan.com/login">易分单</a>
            </li>
            <li>
              <a target="_blank" href="https://www.foonsu.com/sd">速打</a>
            </li>
            <li>
              <a target="_blank" href="https://www.foonsu.com/ylt">易聊天</a>
            </li>
            <li>
              <a target="_blank" href="https://www.foonsu.com/ytc">易通车</a>
            </li>
            <!-- <li>
              <a target="_blank" href="https://www.foonsu.com/ysh">易上货</a>
            </li> -->
            <li>
              <a target="_blank" href="https://www.foonsu.com/ydc">易店涨</a>
            </li>
            <li>
              <a target="_blank" href="https://www.foonsu.com/ybx">易宝箱</a>
            </li>
          </ul>
          <div class="img_box">
            <div class="title">关注我们</div>
            <div class="imgList">
              <div class="imgItem">
                <img src="@/assets/images/icon/accounts.png" alt="" />
                <span>丰速科技</span>
              </div>
              <div class="imgItem">
                <img src="@/assets/images/icon/wechat.jpg" alt="" />
                <span>易聊天需求群</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="banquan">
        <div>
          Copyright © 2021-{{ year }}
          丰速易通（苏州）科技有限公司版权所有，保留所有权利。
        </div>
        <a :href="href2" target="tar_link">苏ICP备2023026061号-5</a>，
        <a class="beian" :href="href1" target="tar_link">
          <img src="@/assets/images/beian.png" />
          <span>苏公网安备32059002004519号</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      navList: [
        { id: 0, text: "首页" },
        { id: 1, text: "功能介绍" },
        { id: 2, text: "功能演示" },
        { id: 3, text: "吸附平台" },
        { id: 4, text: "应用场景" },
        { id: 5, text: "适用人群" },
        { id: 6, text: "版本对比" },
        { id: 7, text: "会员价格" },
      ],
      applyToList: [
        {
          id: 1,
          text1: "业务人员",
          text2: "销售人员",
          imgPath: require("@/assets/images/icon/market-icon.png"),
        },
        {
          id: 2,
          text1: "从事学习/教育",
          text2: "培训人员",
          imgPath: require("@/assets/images/icon/trainCultivate-icon.png"),
        },
        {
          id: 3,
          text1: "天猫/淘宝客服",
          text2: "微商/电商人员",
          imgPath: require("@/assets/images/icon/service-icon.png"),
        },
        {
          id: 4,
          text1: "品牌推广/代理",
          text2: "营销人员",
          imgPath: require("@/assets/images/icon/promotion-icon.png"),
        },
        {
          id: 5,
          text1: "服务人员",
          text2: "客户维护人员",
          imgPath: require("@/assets/images/icon/serve-icon.png"),
        },
      ],
      memberList: [
        /* {
          id: 1,
          imgPath: require("@/assets/images/member/free.png"),
          color: ["#333", "#D1D1D1", "#F5F5F5"],
        }, */
        {
          id: 2,
          imgPath: require("@/assets/images/member/gold.png"),
          color: ["#D99400", "#FFECA6", "#FFFAE5"],
        },
        {
          id: 3,
          imgPath: require("@/assets/images/member/platinum.png"),
          color: ["#5967B3", "#BFCCFF", "#EBEFFF"],
        },
        {
          id: 4,
          imgPath: require("@/assets/images/member/diamond.png"),
          color: ["#3D88F2", "#BFE0FF", "#EBF5FF"],
        },
      ],
      functionList: [
        {
          id: 1,
          text1: "快速登录",
          text2: "通过微信/QQ扫码",
          text3: "快速登录",
          imgPath: require("@/assets/images/home_function_icon/login.png"),
        },
        {
          id: 2,
          text1: "微信多开",
          text2: "支持微信多开",
          text3: "企业微信多开",
          imgPath: require("@/assets/images/home_function_icon/wx_duokai.png"),
        },
        {
          id: 3,
          text1: "团队账号",
          text2: "支持建立多个账号",
          text3: "权限可配",
          imgPath: require("@/assets/images/home_function_icon/tuandui.png"),
        },
        {
          id: 4,
          text1: "资源共享",
          text2: "优质话术加入团队",
          text3: "统一标准",
          imgPath: require("@/assets/images/home_function_icon/ziyuan.png"),
        },
        {
          id: 5,
          text1: "附件发送",
          text2: "支持文件/视频/图片/GIF",
          text3: "等多种格式上传、发送",
          imgPath: require("@/assets/images/home_function_icon/fujian.png"),
        },
        {
          id: 6,
          text1: "导入导出",
          text2: "批量修改话术可快捷",
          text3: "导入导出",
          imgPath: require("@/assets/images/home_function_icon/daorudaochu.png"),
        },
        {
          id: 7,
          text1: "话术管理",
          text2: "三级分类：",
          text3: "页签/文件夹/话术",
          imgPath: require("@/assets/images/home_function_icon/huashu.png"),
        },
        {
          id: 8,
          text1: "多彩标签",
          text2: "颜色区分",
          text3: "快速选中话术主题",
          imgPath: require("@/assets/images/home_function_icon/biaoqian.png"),
        },
        {
          id: 9,
          text1: "一键发送",
          text2: "轻触一键发送/双击",
          text3: "话术发送",
          imgPath: require("@/assets/images/home_function_icon/fasong.png"),
        },
        {
          id: 10,
          text1: "图文发送",
          text2: "图文并发，话术穿插",
          text3: "多张图片",
          imgPath: require("@/assets/images/home_function_icon/tuwenfasong.png"),
        },
        {
          id: 11,
          text1: "快速排序",
          text2: "话术排序：拖拽/右击",
          text3: "选择移动",
          imgPath: require("@/assets/images/home_function_icon/paixu.png"),
        },
        {
          id: 12,
          text1: "快速检索",
          text2: "关键字查找，轻松搞定",
          text3: "筛选",
          imgPath: require("@/assets/images/home_function_icon/jiansuo.png"),
        },
      ],
      platformList: [
        {
          id: 1,
          text1: "微信",
          imgPath: require("@/assets/images/platform_icon/wx.png"),
        },
        {
          id: 2,
          text1: "企业微信",
          imgPath: require("@/assets/images/platform_icon/qiye_wx.png"),
        },
        {
          id: 3,
          text1: "QQ",
          imgPath: require("@/assets/images/platform_icon/QQ.png"),
        },
        {
          id: 4,
          text1: "腾讯企点",
          imgPath: require("@/assets/images/platform_icon/qidian.png"),
        },
        {
          id: 5,
          text1: "京麦",
          imgPath: require("@/assets/images/platform_icon/jm.png"),
        },
        {
          id: 6,
          text1: "Knock",
          imgPath: require("@/assets/images/platform_icon/Knock.png"),
        },
        {
          id: 7,
          text1: "咚咚",
          imgPath: require("@/assets/images/platform_icon/dongdong.png"),
        },
        {
          id: 8,
          text1: "钉钉",
          imgPath: require("@/assets/images/platform_icon/dingding.png"),
        },
        {
          id: 9,
          text1: "拼多多",
          imgPath: require("@/assets/images/platform_icon/pdd.png"),
        },
        {
          id: 10,
          text1: "阿里旺旺",
          imgPath: require("@/assets/images/platform_icon/ww.png"),
        },
        {
          id: 11,
          text1: "飞书",
          imgPath: require("@/assets/images/platform_icon/feishou.png"),
        },
        {
          id: 12,
          text1: "千牛",
          imgPath: require("@/assets/images/platform_icon/qianniu.png"),
        },
        {
          id: 13,
          text1: "招财猫",
          imgPath: require("@/assets/images/platform_icon/zhaocaimao.png"),
        },
        {
          id: 14,
          text1: "1688商家端",
          imgPath: require("@/assets/images/platform_icon/1688.png"),
        },
        {
          id: 15,
          text1: "飞鸽商家端",
          imgPath: require("@/assets/images/platform_icon/feige.png"),
        },
        {
          id: 16,
          text1: "微信客服助手",
          imgPath: require("@/assets/images/platform_icon/wechat_helper.png"),
        },
        {
          id: 17,
          text1: "BOSS直聘",
          imgPath: require("@/assets/images/platform_icon/BOSS.png"),
        },
        {
          id: 18,
          text1: "浏览器",
          imgPath: require("@/assets/images/platform_icon/browser.png"),
        },
        {
          id: 19,
          text1: "万能吸附",
          imgPath: require("@/assets/images/platform_icon/universal.png"),
        },
      ],
      isActive: 0,
      // videoUrl: "http://img.yopoo.cn/banner_video.mp4",
      videoUrl: "https://chat-static.1dadan.com/video/banner_video.mp4.mp4",
      videoUrl2: "https://chat-static.1dadan.com/video/banner_video.mp4",
      pic: require("@/assets/images/home/yiliaotian.png"),
      downloadUrl: `https://yiliaotian.oss-cn-hangzhou.aliyuncs.com/suliao/setup/Yiliaotian_Setup_1.2.0.0.exe?v=${new Date().getTime()}`,
      year: new Date().getFullYear(),
      href1: "https://www.beian.gov.cn/portal/index.do",
      href2: "https://beian.miit.gov.cn/",
      workWxQrCodePng: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  mounted() {
    this.initVideo();
    this.getConfig();
  },
  //方法集合
  methods: {
    // 导航栏切换
    navClick(selector, index) {
      console.log("index", index);
      this.isActive = index;
      document.querySelector(selector).scrollIntoView({ behavior: "smooth" }); //锚点，窗口平稳滚动
    },
    goOne() {
      window.open("https://www.yuque.com/vacefl/fh16te/nn3mzt");
    },
    goTwo() {
      window.open(
        "https://www.yuque.com/docs/share/4aff2f9a-f059-42ff-ad35-bd75b62a5b85?#"
      );
    },
    initVideo() {
      //初始化视频方法
      this.$video("myVideo", {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: "false",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: "798px",
        //设置视频播放器的显示高度（以像素为单位）
        height: "500px",
        // 封面图
        /*  poster:"https://testyiliaotian.oss-cn-hangzhou.aliyuncs.com/foonsuChatImage/yiliaotian.png",
                                        poster:
                                          "https://testyiliaotian.oss-cn-hangzhou.aliyuncs.com/foonsuChatImage/1.png", */
        poster: this.pic,
      });
    },
    initVideo2() {
      //初始化视频方法
      this.$video("myVideo2", {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        // autoplay: "muted",
        autoplay: true,
        muted: true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: "600px",
        //设置视频播放器的显示高度（以像素为单位）
        height: "400px",
        // poster: this.pic,
      });
    },
    goLogin() {
      window.open("https://ht.yiliaotian.com/login");
    },
    openLink() {
      window.open("https://ht.yiliaotian.com");
    },
    getConfig() {
      this.$http.get("/api/show/config/list?type=1087").then((res) => {
        let content = res.data?.data[0]?.content || "";
        content = JSON.parse(content);
        console.log(content);
        this.workWxQrCodePng = content.workWxQrCodePng;
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  destroyed() {}, //生命周期 - 销毁完成
};
</script>
<style lang="scss" scoped>
.app-container {
  .title-h3 {
    font-size: 34px;
    font-weight: bold;
    color: #1f2329;
    text-align: center;
  }
  .header-content {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    .header {
      display: flex;
      align-items: center;
      height: 64px;
      justify-content: space-between;
      // background-color: pink;
      z-index: 1;
      .left {
        display: flex;
        align-items: center;
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
          margin-left: 10px;
        }
      }
      .middle {
        display: flex;
        align-items: center;
        .item {
          height: 64px;
          line-height: 64px;
          font-size: 14px;
          font-weight: 400;
          color: #1f2329;
          margin-right: 30px;
          cursor: pointer;
          transition: 0.2s all linear;
        }
        .item:last-child {
          margin-right: 0px;
        }
        .item:hover {
          color: #006aff;
        }
        .active {
          color: #006aff;
          border-bottom: 3px solid #006aff;
          transition: 0.2s all linear;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .login {
          // width: 72px;
          // height: 32px;
          width: 110px;
          height: 32px;
          line-height: 32px;
          border-radius: 20px;
          border: 1px solid #d6dce4;
          font-weight: 400;
          color: #1f2329;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
        }
        .register {
          // width: 88px;
          // height: 32px;
          width: 110px;
          height: 32px;
          line-height: 32px;
          background: #006aff;
          border-radius: 16px;
          font-weight: 400;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          margin: 0px 15px;
        }
        .download {
          width: 110px;
          height: 32px;
          border: 1px solid #3388ff;
          line-height: 32px;
          border-radius: 16px;
          text-align: center;
          cursor: pointer;
          a {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #006aff;
          }
        }
      }
    }
  }
  .banner-container {
    position: relative;
    .bgc {
      width: 100%;
      min-width: 1200px;
    }
    // .download-container {
    //   position: absolute;
    //   top: 55%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   .title {
    //     font-size: 36px;
    //     font-weight: 400;
    //     color: #51565d;
    //     letter-spacing: 4px;
    //   }
    //   .btn {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 220px;
    //     height: 64px;
    //     line-height: 64px;
    //     background: #006aff;
    //     border-radius: 32px;
    //     font-size: 24px;
    //     color: #ffffff;
    //     cursor: pointer;
    //     border: 2px solid rgba(94, 253, 255, 1);
    //     /*         border-image: linear-gradient(
    //         270deg,
    //         rgba(94, 253, 255, 1),
    //         rgba(56, 89, 255, 1)
    //       )
    //       2 2; */
    //     margin: 40px auto;
    //     text-decoration: none;
    //     img {
    //       margin-left: 10px;
    //       margin-top: 2px;
    //     }
    //   }
    // }
  }
  .understand-content {
    background: linear-gradient(0deg, #fafafa 0%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    padding-bottom: 50px;
    .serviceText {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a6a6a6;
      text-align: center;
      margin-top: 50px;
    }
    .lunbo-content {
      position: relative;
      .overspread {
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-60%, 20%);
        width: 1000px;
        height: 550px;
      }
      .top {
        // padding-top: 45px;
        text-align: center;
        min-width: 1200px;
        .title {
          font-size: 34px;
          font-weight: bold;
          color: #1f2329;
          margin: 20px 0px;
        }
        span {
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: #808080;
          margin: 6px 0px;
        }
      }
      .img-container {
        position: absolute;
        top: 50%;
        right: -8%;
        transform: translate(-50%, 20%);
        // display: none;
        .img {
          height: 480px;
        }
      }
    }
    .list {
      margin-top: 20px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      width: 1200px;
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 183px;
        height: 190px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 0px rgba(217, 233, 255, 0.5);
        border-radius: 5px;
        margin-top: 1.8%;
        transform: scale(1);
        transition: transform 1s ease 0s;
        img {
          margin: 25px 0px 23px 0px;
        }
        .text-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            margin-bottom: 5px;
          }
          .t1 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
          .t2 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
          }
        }
      }
      .item:not(:nth-child(6n)) {
        margin-right: calc(102px / 5);
      }
      .item:hover {
        box-shadow: 0px 12px 24px 0px rgba(234, 234, 234, 0.5);
        transform: scale(1.1);
        transition: transform 1s ease 0s;
      }
      .platformItem {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        float: left;
        width: 183px;
        height: 120px;
        background: #ffffff;
        box-shadow: 0px 4px 20px 0px rgba(217, 233, 255, 0.5);
        border-radius: 5px;
        margin-right: 1.7%;
        margin-top: 1.8%;
        transform: scale(1);
        transition: transform 1s ease 0s;
        .text-container {
          margin-top: 10px;
          .t2 {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
          }
        }
      }
      .platformItem:nth-of-type(6n) {
        margin-right: 0;
      }
      .platformItem:hover {
        box-shadow: 0px 12px 24px 0px rgba(234, 234, 234, 0.5);
        transform: scale(1.1);
        transition: transform 1s ease 0s;
      }
    }
  }
  .function-content {
    position: relative;
    // padding-bottom: 50px;
    // margin-top: 60px;
    .guide {
      width: 600px;
      height: 323px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-image: url("~@/assets/images/guide.gif");
      background-repeat: no-repeat;
      background-size: contain;
      &::after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        background: inherit;
        background-position: center center;
        filter: drop-shadow(0px 0px 10px rgba(255, 160, 160, 0.5)) blur(13px);
        z-index: -1;
      }
    }
    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 0px 40px;
      margin-bottom: 30px;
      .right {
        // position: absolute;
        // right: 0%;
        // margin-top: 300px;
        .title {
          font-size: 22px;
          font-weight: bold;
          color: #1f2329;
          margin-bottom: 20px;
          padding: 50px 0px;
        }
        .t-cont {
          display: flex;
          align-items: baseline;
          margin-bottom: 5px;
          .hint {
            width: 6px;
            height: 6px;
            background-color: #006aff;
            border-radius: 20px;
            margin: 0px 10px;
          }
          .hint-cont {
            padding-bottom: 3px;
            .hint {
              display: block;
              width: 6px;
              height: 6px;
              background-color: #006aff;
              border-radius: 20px;
              margin: 0px 10px;
            }
          }
        }
        .text {
          width: 480px;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          margin-top: 4px;
        }
        .t2 {
          margin-top: 3px;
        }
        .details-cont {
          margin-top: 28px;
          // margin-left: 5px;
          .details {
            font-size: 17px;
            font-weight: 400;
            color: #006aff;
            margin-right: 20px;
            cursor: pointer;
          }
          img {
            cursor: pointer;
          }
        }
        .btn-container {
          display: flex;
          align-items: center;
          margin-top: 50px;
          margin-left: 10px;
          .btn1 {
            width: 150px;
            height: 30px;
            line-height: 30px;
            background: #ffffff;
            border: 1px solid #3388ff;
            border-radius: 19px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #006aff;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .img-container {
        position: absolute;
        top: 10%;
        left: -11%;
      }
    }
  }
  .use-content {
    // margin-top: 50px;
    #myVideo {
      border-radius: 4px;
      // border: 2px solid #5cf6ff;
      box-sizing: border-box;
      margin: 40px auto 0px;
      overflow: hidden;
      ::v-deep .vjs-big-play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #a0a2a8;
      text-align: center;
      margin-top: 40px;
    }
  }
  .applyTo-content {
    // margin-top: 100px;
    // margin-bottom: 40px;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 55px 0px 10px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 180px;
        height: 200px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 20px;
        border: 2px solid #f4f9ff;
        transform: scale(1);
        transition: transform 1s ease 0s;
        img {
          margin-top: 30px;
        }
        .info {
          font-size: 15px;
          font-weight: 400;
          color: #1f2329;
          text-align: center;
          span {
            display: block;
          }
          .t1 {
            margin: 5px 0px;
          }
        }
      }
      .item:hover {
        .info {
          color: #3186ff;
        }
        box-shadow: 0px 12px 24px 0px rgba(234, 234, 234, 0.5);
        transform: scale(1.1);
        transition: transform 1s ease 0s;
      }
    }
  }
  .member-content {
    // margin-top: 100px;
    .bgc {
      display: block;
      width: 100%;
      height: auto;
      margin: 50px 0px 0px 0px;
    }
    .text {
      color: #ff5050;
      margin-top: 10px;
    }
  }
  #myVideo2 {
    border-radius: 20px;
    border: 2px solid #5cf6ff;
    box-sizing: border-box;
    // margin: 40px auto;
    overflow: hidden;
    ::v-deep .vjs-big-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  // 了解价格
  .realize-content {
    // margin-top: 50px;
    .title-h3 {
      margin-bottom: 40px;
    }
    .textInfo {
      font-size: 16px;
      font-weight: 400;
      color: #a0a2a8;
      text-align: center;
      margin: 25px 0px;
    }
    margin-bottom: 80px;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
      }
      span {
        position: absolute;
        width: 180px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        bottom: 30px;
        font-weight: bold;
      }
    }
  }

  /* 右侧提示 */
  .right-tips {
    position: fixed;
    top: 60%;
    right: 29px;
    z-index: 5;
  }

  .right-tips ul li {
    width: 40px;
    height: 40px;
    // background: linear-gradient(#0f69ff, #69a5ff);
    // box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);
    // background: linear-gradient(#f52126, #ffb26e);
    box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);
    border-radius: 50%;
    cursor: pointer;
    margin: 10px;
    text-align: center;
    // border: 2px solid #ffffff;
  }

  // .right-tips ul li:last-child {
  //   width: 40px;
  //   height: 40px;
  //   background: linear-gradient(#f52126, #ffb26e);
  //   box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);+
  //   border-radius: 50%;
  //   margin-top: 20px;
  // }

  .right-tips ul li img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
  }

  .right-tips ul li #welfareTwo {
    position: absolute;
    right: 100px;
    top: 28%;
    right: 64px;
    width: 130px;
    height: 170px;
    background: #ffff;
    box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.12);
    border-radius: 2px;
    z-index: 9999;
    padding: 15px 5px;
    box-sizing: border-box;
    display: none;
  }

  .right-tips ul li #welfare {
    position: absolute;
    right: 100px;
    top: 46%;
    right: 64px;
    width: 131px;
    height: 180px;
    background-color: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);
    border-radius: 2px;
    z-index: 9999;
    padding: 5px;
    box-sizing: border-box;
    display: none;
  }

  .right-tips ul .t3:hover #welfareTwo {
    display: block;
  }

  .right-tips ul .t4:hover #welfare {
    display: block;
  }

  .right-tips ul li #welfare #triangle-right {
    position: absolute;
    top: 49%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
  }

  .right-tips ul li #welfareTwo #triangle-right {
    position: absolute;
    top: 49%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #ffff;
    border-bottom: 10px solid transparent;
  }

  .right-tips ul li #welfare .oRcode {
    width: 120px;
    height: 170px;
    /* border: 1px solid #C9DFFF; */
    border-radius: 4px;
    box-sizing: border-box;
  }

  .right-tips ul li #welfare .oRcode .text1 {
    display: block;
    font-size: 14px;
    color: #0f69ff;
    font-weight: 500;
    margin-top: 10px;
  }

  .right-tips ul li #welfare .oRcode .text2 {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #646d7d;
  }

  .right-tips ul li #welfare .oRcode .img_container {
    width: 105px;
    height: 105px;
    margin-left: 5px;
    margin-top: 5px;
  }

  .right-tips ul li #welfare .oRcode .img_container img {
    width: 80px;
    height: 80px;
    margin-top: 0px;
    border: 1px solid #c9dfff;
    padding: 3px;
    border-radius: 2px;
    overflow: hidden;
  }

  .right-tips ul li #welfareTwo .oRcode {
    border-radius: 4px;
    box-sizing: border-box;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .right-tips ul li #welfareTwo .oRcode .textInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    .t1 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #808080;
    }
  }

  .right-tips ul li #welfareOne {
    position: absolute;
    right: 100px;
    top: 4%;
    right: 64px;
    width: 200px;
    // height: 42px;
    background-color: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);
    border-radius: 2px;
    z-index: 9999;
    padding: 30px 5px 20px;
    box-sizing: border-box;
    display: none;
    & .oRcode {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .qrCode {
      width: 120px;
      height: auto;
      margin-bottom: 10px;
    }
  }

  .right-tips ul li #welfareOne #triangle-right {
    position: absolute;
    top: 9%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
  }

  .right-tips ul .t1:hover #welfareOne {
    display: block;
  }

  .right-tips ul li #welfareThree {
    position: absolute;
    right: 100px;
    top: 35%;
    right: 64px;
    width: 188px;
    height: 40px;
    background-color: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(8, 48, 108, 0.1);
    border-radius: 2px;
    z-index: 9999;
    padding: 5px;
    box-sizing: border-box;
    display: none;
  }

  .right-tips ul li #welfareThree #triangle-right {
    position: absolute;
    top: 33%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 10px solid transparent;
  }

  .right-tips ul .t2:hover #welfareThree {
    display: block;
  }

  /* 底部样式 */
  .footer_wrapper {
    position: relative;
    background: #2c394d;
    // height: 445px;
    padding-bottom: 30px;
  }

  .footer_wrapper .banquan {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    opacity: 0.8;
    padding-top: 18px;
  }

  .footer_wrapper .banquan a {
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .footer_wrapper .banquan a img {
    padding-bottom: 6px;
    margin-right: 3px;
  }

  .footer_wrapper .banquan:hover {
    opacity: 2;
  }

  .footer_wrapper .footer_box {
    display: flex;
    justify-content: center;
  }

  .footer_wrapper .footer_box .title {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin: 30px 0px 20px 0px;
  }
  .footer_wrapper .footer_box .left_box {
    margin-right: 120px;
  }
  .footer_wrapper .footer_box .left_box ul li {
    color: #ffffff;
    opacity: 0.8;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .footer_wrapper .footer_box .left_box ul li:hover {
    opacity: 2;
  }
  .footer_wrapper .footer_box .right_box ul {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #404c5f;
    padding: 10px 0px 30px 0px;
  }
  .footer_wrapper .footer_box .right_box ul li {
    color: #ffffff;
    opacity: 0.8;
    font-size: 13px;
    margin-right: 32px;
    cursor: pointer;
  }
  .footer_wrapper .footer_box .right_box ul li:last-child {
    margin-right: 0px;
  }
  .footer_wrapper .footer_box .right_box ul li a {
    text-decoration: none;
    color: #fff;
  }
  .footer_wrapper .footer_box .right_box ul li:hover {
    opacity: 2;
  }
  .footer_wrapper .footer_box .right_box .img_box .imgList {
    display: flex;
    align-items: center;
  }
  .footer_wrapper .footer_box .right_box .img_box .imgItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 40px;
  }
  .footer_wrapper .footer_box .right_box .img_box .imgItem:last-child {
    margin-right: 0px;
  }
  .footer_wrapper .footer_box .right_box .img_box .imgItem img {
    width: 120px;
    height: 120px;
  }
  .footer_wrapper .footer_box .right_box .img_box .imgItem span {
    color: #ffffff;
    opacity: 0.8;
    font-size: 13px;
    margin-top: 15px;
  }
}
</style>
